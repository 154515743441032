import cx from 'classnames';
import { forwardRef } from 'react';

import { twMerge } from '~/utils/twMerge';

import { Loader } from './loaders/Loader';

export type ButtonVariant = 'compact-dropdown-brand-ghost' | 'primary' | 'secondary' | 'tertiary';

export type ButtonSize = 'xxs' | 'xs' | 'sm' | 'md' | 'lg' | 'xl';
export type ButtonColor = 'blue' | 'default' | 'danger' | 'success' | 'ram-green' | 'ram-yellow' | 'black';
type ButtonShape = 'none' | 'square' | 'circle';

export interface ButtonProps extends React.ComponentPropsWithoutRef<'button'> {
  type: React.ComponentPropsWithoutRef<'button'>['type']; // Make type explicit to avoid subtle errors
  loading?: boolean;
  variant?: ButtonVariant;
  size?: ButtonSize;
  shape?: ButtonShape;
  color?: ButtonColor;
  startIcon?: React.ReactNode;
  endIcon?: React.ReactNode;
}

export const Button = forwardRef<HTMLButtonElement, ButtonProps>(function ButtonWithoutRef(
  {
    size = 'md',
    variant = 'primary',
    color = 'default',
    shape = 'none',
    className,
    loading,
    disabled,
    children,
    startIcon,
    endIcon,
    ...rest
  },
  ref,
) {
  return (
    <button
      ref={ref}
      className={cx(
        getButtonClassNames({
          size,
          variant,
          shape,
          color,
        }),
        className,
      )}
      disabled={loading || disabled}
      {...rest}
    >
      {loading ? <Loader variant="inherit" size="sm" /> : null}
      {startIcon ? startIcon : null}
      {children}
      {endIcon ? endIcon : null}
    </button>
  );
});

const classesByVariantAndColor: Record<ButtonVariant, Record<ButtonColor, string | string[]>> = {
  // Button
  primary: {
    default: [
      'bg-brand-blue border-brand-blue outline-brand-blue/10',
      'hocus:bg-blue-400',
      'aria-expanded:bg-blue-400 text-white',
    ],
    blue: [
      'bg-brand-blue border-brand-blue outline-brand-blue/10',
      'hocus:bg-blue-400',
      'aria-expanded:bg-blue-400 text-white',
    ],
    'ram-green': [
      'bg-brand-green border-0 outline-brand-green/10',
      'hocus:bg-coldgreen-700',
      'aria-expanded:bg-brand-green text-white',
    ],
    'ram-yellow': [
      'bg-brand-yellow border-0 outline-brand-yellow/10',
      'hocus:bg-yellow-300',
      'aria-expanded:bg-yellow text-black',
    ],
    danger: ['bg-red-500 border-red-500 outline-red-500/10', 'hocus:bg-red-400', 'aria-expanded:bg-red-400 text-white'],
    success: [
      'bg-green-400 border-green-400 outline-green-500/10',
      'hocus:bg-green-300',
      'aria-expanded:bg-green-300 text-black',
    ],
    black: [], // not implemented yet
  },
  secondary: {
    default: ['text-black bg-white', 'hover:bg-warmgray-50 aria-expanded:bg-warmgray-100'],
    blue: ['text-brand-blue bg-white', 'hover:bg-warmgray-50'],
    'ram-green': [],
    'ram-yellow': [],
    danger: ['text-red-500 bg-white', 'hover:bg-warmgray-50'],
    success: ['text-warmgreen-500 bg-white', 'hover:bg-warmgray-50'],
    black: ['bg-black text-white border-warmgray-900', 'hover:bg-warmgray-900'],
  },
  tertiary: {
    default: ['text-black', 'hocus:text-warmgray-400'],
    blue: ['text-brand-blue', 'hocus:text-blue-400'],
    danger: ['text-red-500 ', 'hocus:text-red-400'],
    'ram-green': [],
    'ram-yellow': [],
    success: ['text-warmgreen-500 ', 'hocus:text-warmgreen-400'],
    black: [], // not implemented yet
  },
  'compact-dropdown-brand-ghost': {
    default: [],
    'ram-green': [],
    'ram-yellow': [],
    blue: [],
    danger: [],
    success: [],
    black: [],
  },
};

const classesByVariant: Record<ButtonVariant, string | string[]> = {
  // Button
  primary: [
    'border outline-2 text-s-semibold whitespace-nowrap shadow-button-primary',
    'active:shadow-button-primary-active',
    'focus:outline focus:shadow-button-primary',
    'disabled:bg-inactive disabled:border-strong disabled:cursor-not-allowed hocus:disabled:bg-inactive',
  ],
  secondary: [
    'border border-strong border-b-0 text-s-semibold outline-2 outline-brand-blue/10 whitespace-nowrap shadow-button-secondary border-b-transparent',
    'active:shadow-button-secondary-active',
    'focus:outline hover:before:hidden focus:shadow-button-secondary',
    'aria-expanded:before:hidden',
    'disabled:text-inactive disabled:cursor-not-allowed',
  ],
  tertiary: ['text-s-semibold border border-transparent hocus:outline-none hocus:text-brand-blue'],

  // Dropdown
  'compact-dropdown-brand-ghost': [
    'text-s-semibold border border-transparent text-brand-blue hocus:outline-none hover:bg-blue-50 aria-expanded:bg-blue-50 hocus:text-blue-400',
  ],
};

const classesBySize: Record<ButtonSize, string> = {
  xxs: 'px-1.5 py-[.1875rem] rounded-md leading-[1.125rem]', // 20px
  xs: 'px-1.5 py-px rounded-lg', // 24px
  sm: 'px-2 py-1 rounded-lg', // 30px
  md: 'px-2 py-[0.3125rem] rounded-lg', // 32px
  lg: 'px-2 py-[0.4375rem] rounded-lg', // 36px
  xl: 'px-2.5 py-[0.6875rem] rounded-lg', // 44px
};

const classesByShape: Record<ButtonShape, string> = {
  none: '',
  square: 'aspect-square',
  circle: 'rounded-full',
};

const squareLikeClassesOverridesBySize: Record<ButtonSize, string> = {
  xxs: 'p-[.1875rem]',
  xs: 'p-px',
  sm: 'p-[0.125rem]',
  md: 'p-[.3125rem]',
  lg: 'p-[0.4375rem]',
  xl: 'p-[0.6875rem]',
};

export const classesByShapeAndSize: Partial<Record<ButtonShape, Record<ButtonSize, string>>> = {
  square: squareLikeClassesOverridesBySize,
  circle: squareLikeClassesOverridesBySize,
};

const classesByVariantAndSize: Partial<Record<ButtonVariant, Record<ButtonSize, string>>> = {
  primary: {
    xxs: '',
    xs: '',
    sm: '',
    md: 'text-s-semibold',
    lg: 'text-s-semibold',
    xl: 'text-s-semibold',
  },
};

export function getButtonClassNames({
  variant,
  size,
  shape,
  color,
}: {
  variant: ButtonVariant;
  size: ButtonSize;
  shape: ButtonShape;
  color: ButtonColor;
}) {
  const baseClasses = variant === 'tertiary' ? '' : 'inline-flex items-center gap-1 text-center justify-center';
  const sizeClasses = variant === 'tertiary' ? '' : classesBySize[size];

  return twMerge(
    baseClasses,
    classesByVariant[variant],
    classesByVariantAndColor[variant]?.[color],
    sizeClasses,
    classesByVariantAndSize[variant]?.[size],
    classesByShape[shape],
    classesByShapeAndSize[shape]?.[size],
  );
}
